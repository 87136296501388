<template>
  <div style="max-height: 750px; overflow: auto">
    <el-table
      :data="list"
      border
      :header-cell-style="{
        'text-align': 'center',
        background: '#fafafa',
        color: '#444'
      }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column
        :label="typeStatus !== 3 ? '导出配送单数量' : '导出数量'"
        prop="data_size"
      ></el-table-column>
      <el-table-column label="导出状态" prop="status_value"></el-table-column>
      <el-table-column label="操作人" prop="creator_name"></el-table-column>
      <el-table-column
        label="操作时间"
        prop="create_time"
        width="250"
      ></el-table-column>
      <el-table-column label="导出操作" v-slot="scope">
        <el-link type="primary" @click="downLoadFile(scope.row)"
          >点击下载文件</el-link
        >
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px; text-align: center">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        v-model:current-page="formData.page_index"
        :page-sizes="[5, 10, 20, 50, 100]"
        v-model:page-size="formData.page_size"
        layout="total, prev, pager, next, sizes"
        :total="tabelData.total"
      />
    </div>
  </div>
</template>

<script setup>
import { reactive, computed, onMounted, defineProps } from 'vue'
import { exportLogList } from '@/api/jiushi'
import { ElMessage } from 'element-plus'

const props = defineProps({
  typeStatus: Number
})

const formData = reactive({
  page_index: 1,
  page_size: 5
})

const tabelData = reactive({
  list: [],
  total: 0
})

onMounted(() => {
  exportList()
})

const list = computed(() => {
  const data = tabelData.list
  data.map((item) => {
    if (item.status === 0) item.status_value = '正在导出'
    if (item.status === 1) item.status_value = '导出成功'
    if (item.status === 2) item.status_value = '导出失败'
  })
  return data
})

const exportList = () => {
  formData.type = props.typeStatus
  exportLogList({ ...formData }).then((res) => {
    tabelData.list = res.data || []
    tabelData.total = res.page_count || 0
  })
}

const downLoadFile = (row) => {
  if (row.status === 0) {
    ElMessage({
      message: '正在导出，请稍后再试',
      type: 'warning'
    })
    return
  }
  window.open(row.file_url)
}

const sizeChange = (page) => {
  formData.page_size = page
  exportList()
}

const currentChange = (page) => {
  formData.page_index = page
  exportList()
}
</script>
