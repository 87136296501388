<!--
 * @Author: goodLiang
 * @Date: 2023-12-04 17:42:22
 * @LastEditors: goodLiang
 * @LastEditTime: 2024-04-15 11:23:16
 * @FilePath: /business-cms/src/views/manage/sell/list.vue
-->
<template>
  <div class="container">
    <CustomCard>
      <FoldPanel>
        <el-form label-position="left" label-width="140px">
          <el-row :gutter="30">
            <GridItem>
              <el-form-item label="订单号">
                <el-input
                  placeholder="请输入"
                  clearable
                  v-model="formData.order_id"
                ></el-input>
              </el-form-item>
            </GridItem>
            <GridItem>
              <el-form-item>
                <template #label>
                  <el-select v-model="codeData">
                    <el-option label="SKU编码" :value="'sku'"></el-option>
                    <el-option
                      label="商品唯一码"
                      :value="'unique_code'"
                    ></el-option>
                  </el-select>
                </template>
                <el-input
                  placeholder="请输入"
                  clearable
                  v-model="formData[codeData]"
                ></el-input>
              </el-form-item>
            </GridItem>
            <GridItem>
              <el-form-item label="收货人手机号">
                <el-input
                  placeholder="请输入"
                  clearable
                  v-model="formData.mobile"
                ></el-input>
              </el-form-item>
            </GridItem>
            <GridItem>
              <el-form-item label="订单来源">
                <el-select clearable v-model="formData.platform_type_id">
                  <el-option label="全部" :value="''"></el-option>
                  <el-option label="小程序" :value="2"></el-option>
                  <el-option label="APP" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </GridItem>
            <GridItem>
              <el-form-item label="收货方式">
                <el-select clearable v-model="formData.send_type">
                  <el-option label="全部" :value="''"></el-option>
                  <el-option label="存入酒库" :value="2"></el-option>
                  <el-option label="物流发货" :value="1"></el-option>
                  <el-option label="自提" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </GridItem>
            <GridItem>
              <el-form-item label="存酒状态">
                <el-select clearable v-model="formData.wine_cellar_status">
                  <el-option label="全部" :value="''"></el-option>
                  <el-option label="正在存入" :value="1"></el-option>
                  <el-option label="已存入" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </GridItem>
            <GridItem>
              <el-form-item label="支付方式">
                <el-select clearable v-model="formData.pay_method_id">
                  <el-option label="全部" :value="''"></el-option>
                  <el-option label="钱包支付" :value="1"></el-option>
                  <el-option label="微信支付" :value="2"></el-option>
                  <el-option label="对公转账" :value="3"></el-option>
                  <el-option label="支付宝支付" :value="12"></el-option>
                  <el-option label="支付单拆分支付" :value="14"></el-option>
                  <el-option label="银行转账" :value="15"></el-option>
                </el-select>
              </el-form-item>
            </GridItem>
            <GridItem :lg="16">
              <el-form-item label="支付时间">
                <el-date-picker
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  v-model="payTime"
                >
                </el-date-picker>
              </el-form-item>
            </GridItem>
            <GridItem :lg="16">
              <el-form-item label="下单时间">
                <el-date-picker
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  v-model="commitTime"
                >
                </el-date-picker>
              </el-form-item>
            </GridItem>
          </el-row>
        </el-form>
        <template #operation>
          <el-button type="primary" @click="lookUp">查询</el-button>
          <el-button @click="resetFromData">重置</el-button>
          <el-button @click="showExport(true)">导出</el-button>
          <el-link style="margin-left: 10px" @click="showRecard(true)"
            >导出记录</el-link
          >
        </template>
      </FoldPanel>
      <el-divider></el-divider>
      <div class="tab-box">
        <div
          v-for="(item, index) in orderStatus.list.slice(0, 5)"
          :key="item.value"
          :class="`tab${orderStatusIndex === index ? ' active' : ''}`"
          @click="changeTab(index)"
        >
          {{ item.label }}
        </div>
      </div>
      <div v-if="list.length">
        <div class="table-header">
          <div class="column i-text-left" style="width: 20%">商品信息</div>
          <div class="column" style="width: 14%">售价(元)/数量</div>
          <div class="column" style="width: 14%">转账手续费</div>
          <div class="column" style="width: 14%">平台佣金</div>
          <div class="column" style="width: 13%">实收金额(元)</div>
          <div class="column" style="width: 13%">买家/收货人</div>
          <div class="column" style="width: 10%">收货方式</div>
          <div class="column" style="width: 10%">订单/售后状态</div>
          <div class="column" style="width: 10%">操作</div>
        </div>
        <div class="goods-info" v-for="item in list" :key="item.order_id">
          <div class="header">
            <div class="header-left">
              <div class="mr20">
                订单号：<span
                  class="i-text-primary"
                  style="cursor: pointer"
                  @click="toDetail(item.order_id, item.send_type)"
                  >{{ item.order_id }}</span
                >
              </div>
              <div class="mr20">下单时间：{{ item.order_submit_time }}</div>
              <div class="mr20">支付时间：{{ item.order_pay_time }}</div>
              <div class="mr20">
                {{ item.platform_type_value
                }}<span v-show="item.pay_method_id"
                  ><span style="color: #999"> ｜ </span>
                  {{ item.pay_method_value }}</span
                >
              </div>
            </div>
            <el-link
              type="primary"
              @click="toDetail(item.order_id, item.send_type)"
              >订单详情</el-link
            >
          </div>
          <div class="detail">
            <div class="column goods" style="width: 20%">
              <img :src="item.commodity_image_url" alt="" />
              <div class="info">
                <div class="title">{{ item.commodity_name }}</div>
                <div class="code">商品编码：{{ item.sku }}</div>
              </div>
            </div>
            <div class="column" style="width: 14%; font-weight: 500">
              ¥{{ item.sale_amount / 100 }} / {{ item.sale_number }}
            </div>
            <div
              class="column"
              style="
                width: 14%;
                font-weight: 500;
                display: block;
                text-align: center;
              "
            >
              <p style="margin-top: 30px">
                {{ item.transfer_fee / 100 }}
              </p>
              <p>（费率：{{ item.transfer_fee_percent * 100 }}%）</p>
            </div>
            <div
              class="column"
              style="
                width: 14%;
                font-weight: 500;
                display: block;
                text-align: center;
              "
            >
              <p style="margin-top: 30px">
                {{ item.platform_commission / 100 }}
              </p>
              <p>（费率：{{ item.platform_commission_percent * 100 }}%）</p>
            </div>
            <div
              class="column"
              style="width: 13%; font-weight: 500; color: #ef2a31"
            >
              ¥{{ item.pay_amount / 100 }}
            </div>
            <div class="column" style="width: 13%">
              <div>
                <p>{{ item.user_name }}</p>
                <p>{{ item.mobile }}</p>
              </div>
            </div>
            <div class="column" style="width: 10%">
              {{ item.send_type_value }}
            </div>
            <div class="column" style="width: 10%">
              {{ item.order_status_name }}
              <span
                :class="item.refund_servcie_id ? 'clrcp' : 'clr'"
                @click="toAfterSale(item.refund_service_id)"
                v-if="item.order_refund_status_name"
                >/{{ item.order_refund_status_name }}</span
              >
            </div>
            <div class="column" style="width: 10%">
              <el-link
                type="primary"
                v-if="item.send_type === 1"
                @click="getLogisticesInfo(item.order_id)"
                >物流信息</el-link
              >
              <el-link
                type="primary"
                @click="sendCancelOrder(item.order_id)"
                v-if="item.has_show_cancel_order_link > 0"
                >取消订单</el-link
              >
              <div v-if="item.send_type === 2 && item.order_status === 4">
                已存入
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #bottomOperation v-if="list.length">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          v-model:current-page="formData.page_index"
          :page-sizes="[10, 20, 50, 100]"
          v-model:page-size="formData.page_size"
          layout="total, prev, pager, next, sizes"
          :total="tabelData.total"
        />
      </template>
      <div class="goods-info no-data" v-if="!list.length">暂无数据</div>
    </CustomCard>

    <el-dialog title="提示" v-model="showExportRadio" width="35%">
      <div class="export-style">
        <p>请选择订单导出范围</p>
        <el-radio-group v-model="radio">
          <el-radio :label="1">导出当前页全部订单</el-radio>
          <el-radio :label="2">导出全部订单</el-radio>
        </el-radio-group>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showExport(false)">取 消</el-button>
          <el-button type="primary" @click="exportData">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog title="导出记录" v-model="showExportRecard" width="55%">
      <ExportRecard :key="reRender" :typeStatus="type" />
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="showRecard(false)">关 闭</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog title="物流信息" v-model="showLogisticsInfo" width="55%">
      <LogisticesInfo
        :key="reRenderLog"
        :showLogisticsList="showLogisticsList"
      />
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="showLogisticsInfo = false"
            >关 闭</el-button
          >
        </span></template
      >
    </el-dialog>

    <el-dialog title="选择取消订单原因" v-model="showCancelOrder" width="55%">
      <el-form label-width="110px">
        <el-form-item required label="取消原因">
          <el-select clearable v-model="refundReason" placeholder="请选择">
            <el-option
              v-for="item in reasonList"
              :key="item.code"
              :label="item.reason"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showCancelOrder = false">暂不取消</el-button>
          <el-button type="primary" @click="cancelOrder">取消订单</el-button>
        </span></template
      >
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, watch, computed, onMounted } from 'vue'
import CustomCard from '@/components/CustomCard'
import FoldPanel from '@/components/FoldPanel'
import GridItem from '@/components/GridItem'
import LogisticesInfo from '@/components/LogisticsInfo'
import ExportRecard from '@/components/ExportRecard'
import { popOrderList, popOrderExport, popOrderDetail } from '@/api/jiushi'
import { getTime } from '@/utils/util'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { queryRefundReasonList, addRefundServiceInfo } from '@/api/aftersale'

// defineOptions({ name: 'SaledGoods' })

const router = useRouter()

const codeData = ref('sku')
const payTime = ref([])
const commitTime = ref([])
const orderStatusIndex = ref(0)
const orderStatus = reactive({
  list: [
    {
      label: '全部',
      value: ''
    },
    {
      label: '待发货',
      value: 2
    },
    {
      label: '已发货',
      value: 3
    },
    {
      label: '已完成',
      value: 4
    },
    {
      label: '已取消',
      value: 5
    },
    {
      label: '已关闭',
      value: 6
    },
    {
      label: '待确认',
      value: 7
    },
    {
      label: '物流发货待调拨',
      value: 8
    },
    {
      label: '存入酒库待调拨',
      value: 9
    },
    {
      label: '自提待调拨',
      value: 10
    }
  ]
})
const formData = reactive({
  order_id: '',
  sku: '',
  unique_code: '',
  mobile: null,
  platform_type_id: '',
  order_status: '',
  send_type: '',
  wine_cellar_status: '',
  pay_method_id: '',
  order_pay_time_start: '',
  order_pay_time_end: '',
  order_submit_time_start: '',
  order_submit_time_end: '',
  page_index: 1,
  page_size: 10
})

const tabelData = reactive({
  list: [],
  total: 0
})

const type = ref(0)
const radio = ref(1)
const reRender = ref(0)
const reRenderLog = ref(0)
const showExportRadio = ref(false)
const showExportRecard = ref(false)
const showLogisticsInfo = ref(false)
const showLogisticsList = ref([])

const reasonList = ref([])
const refundReason = ref(undefined)
const orderId = ref('')

onMounted(() => {
  getList()

  queryRefundReasonList({
    type: 1
  }).then((res) => {
    reasonList.value = res.list || []
  })
})

watch(codeData, () => {
  formData.sku = ''
  formData.unique_code = ''
})

watch(payTime, (newVal, oldVal) => {
  if (newVal.length > 0) {
    formData.order_pay_time_start = getTime(newVal[0])
    formData.order_pay_time_end = getTime(newVal[1])
  }
})

watch(commitTime, (newVal, oldVal) => {
  if (newVal.length > 0) {
    formData.order_submit_time_start = getTime(newVal[0])
    formData.order_submit_time_end = getTime(newVal[1])
  }
})

watch(showExportRecard, (newVal) => {
  if (newVal) reRender.value = Math.random()
})

watch(showLogisticsInfo, (newVal) => {
  if (newVal) reRenderLog.value = Math.random()
})

const showExport = (v) => {
  console.log(v)
  showExportRadio.value = v
  console.log(showExportRadio.value)
}

const showRecard = (v) => {
  showExportRecard.value = v
}

const list = computed(() => {
  const data = tabelData.list
  data.map((item) => {
    if (item.platform_type_id === 1) item.platform_type_value = 'APP'
    if (item.platform_type_id === 2) item.platform_type_value = '小程序'
    if (item.pay_method_id === 1) item.pay_method_value = '钱包支付'
    if (item.pay_method_id === 2) item.pay_method_value = '微信支付'
    if (item.pay_method_id === 3) item.pay_method_value = '对公转账'
    if (item.pay_method_id === 12) item.pay_method_value = '支付宝支付'
    if (item.pay_method_id === 14) item.pay_method_value = '支付单拆分支付'
    if (item.pay_method_id === 15) item.pay_method_value = '银行转账'
    // item.sale_amount = item.sale_amount / 100
    // item.pay_amount = item.pay_amount / 100
    if (item.send_type === 1) item.send_type_value = '物流发货'
    if (item.send_type === 2) item.send_type_value = '存入酒库'
    if (item.send_type === 3) item.send_type_value = '自提'
    item.order_status_value = orderStatus.list.find(
      (ele) => ele.value === item.order_status
    ).label
    if (item.send_type === 3 && item.order_status === 3) {
      item.order_status_value = '待自提'
    }
  })
  return data
})

const getList = () => {
  popOrderList({ ...formData }).then((res) => {
    tabelData.list = res.data || []
    tabelData.total = res.page_count || 0
  })
}

const changeTab = (index) => {
  orderStatusIndex.value = index
  formData.order_status = orderStatus.list[index].value
  getList()
}

const lookUp = () => {
  formData.page_index = 1
  getList()
}

const resetFromData = () => {
  payTime.value = []
  commitTime.value = []
  formData.order_id = ''
  formData.sku = ''
  formData.unique_code = ''
  formData.mobile = null
  formData.platform_type_id = ''
  formData.send_type = ''
  formData.wine_cellar_status = ''
  formData.pay_method_id = ''
  formData.order_pay_time_start = ''
  formData.order_pay_time_end = ''
  formData.order_submit_time_start = ''
  formData.order_submit_time_end = ''
  formData.page_index = 1
  getList()
}

const exportData = () => {
  // let order_id_list = null
  // if (radio.value === 1) order_id_list = tabelData.list.map(item => item.order_id)
  if (radio.value === 2) formData.order_status = null
  popOrderExport({ ...formData }).then((res) => {
    // fileExportProcessing(".xlsx", res)
    ElMessage.success(res)
    showExportRadio.value = false
  })
}

const getLogisticesInfo = (order_id) => {
  popOrderDetail({ order_id }).then((res) => {
    showLogisticsList.value = res.logistics_list || []
    showLogisticsInfo.value = true
  })
}

const toDetail = (orderId, sendType) => {
  console.log('router :>> ', router)
  router.push(`/manage/sell/detail?orderId=${orderId}&sendType=${sendType}`)
}

const sizeChange = (page) => {
  formData.page_size = page
  getList()
}

const currentChange = (page) => {
  formData.page_index = page
  getList()
}

const showCancelOrder = ref(false)
const sendCancelOrder = (id) => {
  orderId.value = id
  showCancelOrder.value = true
}
const cancelOrder = () => {
  if (!refundReason.value) {
    ElMessage({
      type: 'warning',
      message: '请选择取消原因'
    })
    return
  }
  addRefundServiceInfo({
    system_source: 2,
    order_id: orderId.value,
    refund_type: 1,
    refund_reason: refundReason.value,
    create_type: 3,
    refund_commodity_list: [
      {
        wine_order_id: orderId.value
      }
    ]
  }).then((res) => {
    showCancelOrder.value = false
    ElMessage({
      type: 'success',
      message: '操作成功！'
    })
  })
}
const toAfterSale = (refundServiceId) => {
  if (!refundServiceId) return
  router.push(`/manage/service/detail/${refundServiceId}`)
}
</script>

<style lang="scss" scoped>
.container {
  .tab-box {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    padding: 4px;
    background: #f3f5f7;
    border-radius: 4px;
    margin-bottom: 20px;

    .tab {
      cursor: pointer;
      padding: 8px 24px;
      border-radius: 2px;
    }

    .active {
      color: #0071db;
      background: #ffffff;
    }
  }

  .table-header {
    width: 100%;
    background: #f3f5f7;
    border-radius: 2px;
    border: 1px solid #dce6f0;
    margin-bottom: 20px;
    display: flex;

    .column {
      box-sizing: border-box;
      border-right: 1px solid #dce6f0;
      height: 40px;
      text-align: center;
      line-height: 40px;
      color: #484d52;
      padding: 0 20px;
    }

    .column:last-child {
      border-right: 1px solid transparent;
    }
  }

  .goods-info {
    width: 100%;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #dce6f0;
    margin-bottom: 30px;

    .header {
      box-sizing: border-box;
      padding: 0 20px;
      height: 48px;
      background: #f7fafc;
      border-radius: 2px;
      border-bottom: 1px solid #dce6f0;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-left {
        display: flex;
      }
    }

    .detail {
      display: flex;

      .column {
        height: 105px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #dce6f0;
        font-size: 14px;
        padding: 0 20px;
      }

      .column:last-child {
        border-right: 1px solid transparent;
      }
      .column.goods {
        justify-content: flex-start;
      }
      .goods {
        img {
          width: 64px;
          height: 64px;
          background: #c4c4c4;
          margin-right: 8px;
        }

        .info {
          width: 58%;
          height: 64px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            width: 100%;
            height: 32.5px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #262626;
          }

          .code {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #484d52;
          }
        }
      }
    }
  }

  .mr20 {
    margin-right: 20px;
  }

  .no-data {
    height: 153px;
    color: #262626;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.export-style {
  padding: 30px;
  background: #f7fafc;

  p {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #001426;
    margin-bottom: 30px;
  }
}
</style>
